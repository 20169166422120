<template>
  <div>
    <div class="editar-notificacao">
      <div class="conteudo-interno">
        <div class="nova-comunicacao-container">
          <h1>Editar Notificação</h1>

          <div class="formulario-container">
            <div class="formulario-linha linha-tripla">
              <div class="formulario-item medio">
                <label class="formulario-item-label">Tipo de Notificação</label>
                <input type="text" v-model="form.tipoNotificacao" placeholder="Tipo de Notificação" disabled />
              </div>

              <div class="formulario-item medio">
                <label class="formulario-item-label">Criado por</label>
                <input type="text" v-model="form.criadoPor" placeholder="Criado por" disabled />
              </div>

              <div class="formulario-item medio">
                <label class="formulario-item-label">Data de envio</label>
                <input type="text" v-model="form.dataEnvio" placeholder="Criando em" disabled />
              </div>

              <div class="formulario-item medio">
                <label class="formulario-item-label">Criado em</label>
                <input type="text" v-model="form.criadaEm" placeholder="Criando em" disabled />
              </div>

              <div class="formulario-item medio">
                <label class="formulario-item-label">Status</label>
                <multiselect v-model="form.status" label="descricao" track-by="descricao" :options="status"
                  select-label="Selecionar" placeholder="Selecione" deselect-label=" " selected-label="Selecionado"
                  :allow-empty="false" />
              </div>

              <div class="formulario-item" id="txt-msg">
                <label class="formulario-item-label">Mensagem</label>
                <textarea v-model="form.mensagem" class="textarea-msg" disabled />
              </div>
            </div>
          </div>
          <div class="container-btns">
            <button class="btn btn-laranja" @click="salvar()">Salvar</button>
          </div>

          <p class="h2">Segmentação</p>
          <div class="formulario-container">
            <div class="formulario-linha linha-tripla">
              <div class="formulario-item medio">
                <label class="formulario-item-label">Segmentação</label>
                <multiselect v-model="busca.segmentacao" label="descricao" track-by="descricao"
                  :options="selectSegmentacao" placeholder="Tipo de segmentação" select-label="Selecionar"
                  deselect-label=" " selected-label="Selecionado" :allow-empty="false" ref="segmentacao" @input="
                    (busca.segmentarPorAutorizada = []),
                    (assistenciaSelecionados = [])
                  " />
              </div>

              <div class="formulario-item medio" v-if="busca.segmentacao.id == 2">
                <label class="formulario-item-label">CPF</label>
                <input type="text" v-model="busca.cpf" v-mask="'###.###.###-##'" placeholder="CPF" />
              </div>

              <div class="formulario-item medio" v-if="busca.segmentacao.id == 3">
                <label class="formulario-item-label">Assistência</label>
                <multiselect v-model="busca.segmentarPorAutorizada" label="descricao" track-by="descricao"
                  :options="selectAssistencias" placeholder="Selecione as Assistências" select-label="Selecionar"
                  deselect-label=" " selected-label="Selecionado" :allow-empty="false"
                  @input="assistenciaSelecionado()" />
              </div>

              <div class="formulario-item medio" v-if="busca.segmentacao.id == 4">
                <label class="formulario-item-label">Franquia</label>
                <multiselect v-model="busca.segmentarPorAutorizada" label="descricao" track-by="descricao"
                  :options="selectFranquias" placeholder="Selecione as Franquias" select-label="Selecionar"
                  deselect-label=" " selected-label="Selecionado" :allow-empty="false"
                  @input="assistenciaSelecionado()" />
              </div>

              <div class="formulario-item medio">
                <label class="formulario-item-label">Status</label>
                <multiselect v-model="busca.status" label="descricao" track-by="descricao" :options="status"
                  placeholder="Selecione o status" select-label="Selecionar" deselect-label=" "
                  selected-label="Selecionado" :allow-empty="false" />
              </div>

              <div class="formulario-item medio" v-if="busca.segmentacao.id == 3 || busca.segmentacao.id == 4">
                <label class="formulario-item-label">Cargo</label>

                <multiselect v-model="busca.idCargo" label="descricao" track-by="descricao" :options="selectCargo"
                  placeholder="Selecione o cargo" select-label="Selecionar" deselect-label=" "
                  selected-label="Selecionado" :allow-empty="false" />
              </div>

              <div id="box-filtro-assistencia">
                <div class="box-excluir-todos">
                  <p class="btn-excluir" @click="
                    (busca.segmentarPorAutorizada = []),
                    (assistenciaSelecionados = [])
                  " v-if="assistenciaSelecionados.length > 0">
                    Limpar seleção
                  </p>
                </div>
                <div v-if="assistenciaSelecionados.length > 0" class="tabela-informacoes">
                  <div class="box-colunas-assistencia tabela-linha tabela-linha-informacoes quebra-linha">
                    <div v-for="(assistencia, index) in assistenciaSelecionados" :key="index"
                      class="coluna-nome-assistencia">
                      <p>{{ assistencia.descricao }}</p>
                      <button @click="
                        assistenciaSelecionados.splice(
                          assistenciaSelecionados.indexOf(assistencia),
                          1
                        )
                      " class="btn-excluir-assistencia">
                        X
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="container-btns">
            <button class="btn btn-cancelar" @click="limparFiltro">
              Limpar
            </button>
            <button class="btn btn-laranja" @click="filtroSegmentacoes()">
              Filtrar
            </button>
          </div>

          <div class="segmentacoes">
            <div class="tabela-informacoes" key="Lista">
              <div class="tabela-linha tabela-linha-titulo">
                <div class="tabela-linha-nome">
                  <p>Segmentação</p>
                </div>
                <div class="tabela-linha-nome">
                  <p>Cargo</p>
                </div>
                <div class="tabela-linha-nome">
                  <p>CPF/CNPJ</p>
                </div>
                <div class="tabela-linha-data">
                  <p>Status</p>
                </div>
                <div class="tabela-linha-data">Ação</div>
              </div>

              <div v-if="buscaTabela.length > 0">
                <div class="tabela-linha tabela-linha-informacoes" v-for="(item, index) in buscaTabela" :key="index">
                  <div class="tabela-linha-nome">
                    <p>{{ item.tipoAutorizada }}</p>
                  </div>
                  <div class="tabela-linha-nome">
                    <p>{{ item.cargo }}</p>
                  </div>
                  <div class="tabela-linha-nome">
                    <p>{{ item.cnpj ? item.cnpj : item.cpf }}</p>
                  </div>
                  <div class="tabela-linha-data">
                    <p>{{ item.status ? "Ativo" : "Inativo" }}</p>
                  </div>
                  <div class="tabela-linha-data">
                    <p>
                      <a href="#" @click.prevent="
                        inativaNotificaoParticipante(
                          index,
                          item.id,
                          item.status
                        )
                      ">{{ item.status ? "Desativar" : "Ativar" }}</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <p class="h2">Adicionar nova segmentação</p>
          <formulario-segmentacao :editar="false" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import moment from "moment";
import {
  obterDetalhes,
  listarDetalhesSegmentacoes,
  filtroListarSegmentacoes,
} from "@/services/notificacoes";
import FormularioSegmentacao from "@/components/elementos/paginas/FormularioSegmentacao";
import { listarAssistencias } from "@/services/autorizadas";
import { listarCargos } from "@/services/espelho";

export default {
  components: {
    Multiselect,
    FormularioSegmentacao,
  },
  data() {
    return {
      status: [
        { id: true, descricao: "Ativo" },
        { id: false, descricao: "Inativo" },
      ],
      dadosTabela: [],
      assistenciaSelecionados: [],
      selectAssistencias: [],
      selectSegmentacao: [
        { id: 2, descricao: "Por participante" },
        { id: 3, descricao: "Por assistência" },
        { id: 4, descricao: "Por franquia" },
      ],
      form: {
        tipoNotificacao: "",
        mensagem: "",
        criadoPor: "",
        criadaEm: "",
        dataEnvio: "",
        status: {
          id: "",
        },
      },
      busca: {
        cpf: "",
        segmentacao: [],
        segmentarPorAutorizada: [],
        idCargo: [],
        status: [],
      },
      selectCargo: [],
      assistenciaFranquia: [],
      selectStatus: [],
      buscaTabela: [],
    };
  },
  created() {
    this.obterDados();
    this.listarDetalhesSegmentacoes();
    this.listaCargos();
    this.buscarAssistenciasFranquias();
  },
  methods: {
    limparFiltro() {
      this.busca = {
        cpf: "",
        segmentacao: [],
        segmentarPorAutorizada: [],
        idCargo: [],
        status: [],
      };
      this.assistenciaSelecionados = [];

      this.listarDetalhesSegmentacoes();
    },
    filtroSegmentacoes() {
      const data = {
        idTipoNotificacao: this.$route.params.id,
        idCargo: this.busca.idCargo.id ? this.busca.idCargo.id : 0,
        cpf: this.busca.cpf
          ? this.busca.cpf.replaceAll(".", "").replace("-", "")
          : "",
        idAutorizada: this.busca.segmentarPorAutorizada.id
          ? this.busca.segmentarPorAutorizada.id
          : 0,
        status: this.busca.status != [] ? this.busca.status.id : "",
        pagina: 1,
        qtdPorPagina: 20,
      };
      filtroListarSegmentacoes(data).then((resp) => {
        this.buscaTabela = resp.data;
      });
    },
    formatarData(data) {
      return moment(data).format("DD/MM/YYYY");
    },
    assistenciaSelecionado() {
      if (this.form.segmentarPorAutorizada != null) {
        this.assistenciaSelecionados.push(this.form.segmentarPorAutorizada);
        this.form.segmentarPorAutorizada = this.assistenciaSelecionados;
      }
    },
    buscarAssistenciasFranquias() {
      listarAssistencias(1).then((resp) => {
        this.selectAssistencias = resp.data;
      });
      listarAssistencias(2).then((resp) => {
        this.selectFranquias = resp.data;
      });
    },
    listaCargos() {
      listarCargos().then((resp) => {
        this.selectCargo = resp.data;
      });
    },
    listarDetalhesSegmentacoes() {
      let id = this.$route.params.id;
      listarDetalhesSegmentacoes(id, 3).then((resp) => {
        this.buscaTabela = resp.data.lista;
      });
    },
    inativaNotificaoParticipante(index, id, status) {
      let novoStatus = status ? false : true;
      listarDetalhesSegmentacoes(id, novoStatus).then(() => {
        this.listarDetalhesSegmentacoes();
        this.buscaTabela[index].status = !status;
      });
    },
    obterDados() {
      let id = this.$route.params.id;
      obterDetalhes(id, 3).then((resp) => {
        this.form = resp.data.dados;
        this.form.criadaEm = moment(resp.data.dados.criadaEm).format(
          "DD/MM/YYYY"
        );
        this.form.dataEnvio = moment(resp.data.dados.dataEnvio).format(
          "DD/MM/YYYY"
        );
        this.form.status = resp.data.dados.status == true ? { id: true, descricao: "Ativo" } : { id: false, descricao: "Inativo" };
      });
    },
    salvar() {
      let id = this.$route.params.id;
      let status = !this.form.status.id;
      obterDetalhes(id, status).then((resp) => {
        if (resp.data.sucesso) {
          this.$store.commit("SET_MODAL", {
            ativado: true,
            mensagem: resp.data.mensagem,
            tipo: "sucesso",
          });
        }
      });
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css">

</style>
<style lang="scss" scoped>
.editar-notificacao {
  position: relative;
  display: flex;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

#txt-msg {
  width: 100%;

  .textarea-msg {
    width: 100%;
    padding: 8px;
    height: 100px;
    max-height: max-content;
  }
}

.busca-segmentacoes {
  width: 100%;
  margin: 20px 0;
  display: flex;
  width: 100%;
}

.segmentacoes {
  max-height: 300px;
  overflow-x: auto;
}

.h2 {
  font-size: 30px;
  padding-top: 60px;
}
</style>
