<template>
	<form @submit.prevent class="formulario-container">
		<div class="formulario-linha linha-tripla">
			<div class="formulario-item medio">
				<label class="formulario-item-label">Segmentação</label>
				<multiselect v-model="form.segmentacao" label="descricao" track-by="descricao"
					:options="selectSegmentacao" placeholder="Selecione a segmentação" select-label="Selecionar"
					deselect-label=" " selected-label="Selecionado" :allow-empty="false" @close="fecharSelect" @input="
						(form.segmentarPorAutorizada = []), (assistenciaSelecionados = [])
					" />
			</div>

			<div class="formulario-item medio" v-if="form.segmentacao.id && form.segmentacao.id == 2">
				<label class="formulario-item-label">CPF</label>
				<input type="text" v-model="form.cpf" v-mask="'###.###.###-##'" placeholder="CPF" />
			</div>

			<div class="formulario-item medio" v-if="form.segmentacao.id && form.segmentacao.id == 3">
				<label class="formulario-item-label">Assistência</label>
				<multiselect v-model="form.segmentarPorAutorizada" label="descricao" track-by="descricao"
					:options="selectAssistencias" placeholder="Selecione as Assistências" select-label="Selecionar"
					deselect-label=" " selected-label="Selecionado" :allow-empty="false" @close="fecharSelect"
					@input="assistenciaSelecionado()" />
			</div>

			<div class="formulario-item medio" v-if="form.segmentacao.id && form.segmentacao.id == 4">
				<label class="formulario-item-label">Franquia</label>
				<multiselect v-model="form.segmentarPorAutorizada" label="descricao" track-by="descricao"
					:options="selectFranquias" placeholder="Selecione as Franquias" select-label="Selecionar"
					deselect-label=" " selected-label="Selecionado" :allow-empty="false" @close="fecharSelect"
					@input="assistenciaSelecionado()" />
			</div>

			<div class="formulario-item container-upload" v-if="form.segmentacao.id && form.segmentacao.id != ''">
				<div class="medio">
					<label class="formulario-item-label text-center">{{
							nomeAnexoplanilha
					}}</label>
				</div>
				<input id="input-planilha" type="file" accept=".xls, .xlsx"
					@change="atualizarPlanilha('planilha', 'arquivoPlanilha', $event)" />

				<div id="planilha-flex">
					<label for="input-planilha" class="planilha-btn btn-icon">Adicionar planilha</label>
					<transition name="fade">
						<a v-if="arquivoPlanilha" class="remove-image" @click="
							removerPlanilha('planilha', 'arquivoPlanilha', 'input-planilha')
						">Remover</a>
					</transition>
				</div>
			</div>

			<div id="box-filtro-assistencia">
				<div class="box-excluir-todos">
					<p class="btn-excluir" @click="(assistenciaSelecionados = []), (form.idsSegmentacao = [])"
						v-if="assistenciaSelecionados.length > 0">
						Limpar seleção
					</p>
				</div>
				<div v-if="assistenciaSelecionados.length > 0" class="tabela-informacoes">
					<div class="
                    box-colunas-assistencia
                    tabela-linha tabela-linha-informacoes
                    quebra-linha
                  ">
						<div v-for="(assistencia, index) in assistenciaSelecionados" :key="index"
							class="coluna-nome-assistencia">
							<p>{{ assistencia.descricao }}</p>
							<button @click="
								assistenciaSelecionados.splice(
									assistenciaSelecionados.indexOf(assistencia),
									1
								)
							" class="btn-excluir-assistencia">
								X
							</button>
						</div>
					</div>
				</div>
			</div>

			<!-- <div class="formulario-item medio">
				<label class="formulario-item-label">Cargo</label>

				<multiselect
					v-model="form.idCargo"
					label="descricao"
					track-by="descricao"
					:options="selectCargo"
					placeholder="Selecione o cargo"
					select-label="Selecionar"
					deselect-label=" "
					selected-label="Selecionado"
					:allow-empty="false"
					@close="fecharSelect"
				/>
			</div> -->

			<!-- <div class="formulario-item medio">
				<label class="formulario-item-label">Data de envio</label>
				<datepicker v-model="form.dataEnvio" :language="ptBR" />
			</div> -->
		</div>

		<div class="container-btns">
			<button class="btn btn-cancelar" @click="$router.push('/cadastro-notificacoes/listar')">
				Voltar
			</button>
			<button class="btn btn-laranja" @click="salvar()">Adicionar</button>
		</div>
	</form>
</template>

<script>
// import Datepicker from "vuejs-datepicker";
import { ptBR } from "vuejs-datepicker/dist/locale";
import Multiselect from "vue-multiselect";
import {
	listarTipoAutorizada,
	listarAssistencias
} from "@/services/autorizadas";
import { listarCargos } from "@/services/espelho";
import { adicionarNovaSegmentacao } from "@/services/notificacoes";
import moment from "moment";
export default {
	name: "FormularioBannerTreinamento",
	props: ["editar"],
	components: {
		Multiselect,
		// Datepicker
	},
	data() {
		return {
			ptBR: ptBR,
			selectAssistencias: [],
			selectFranquias: [],
			selectCargo: [],
			selectSegmentacao: [
				{ id: 2, descricao: "Por participante" },
				{ id: 3, descricao: "Por assistência" },
				{ id: 4, descricao: "Por franquia" }
			],
			assistenciaSelecionados: [],
			form: {
				id: "",
				cpf: "",
				idsSegmentacao: [],
				planilha: "",
				segmentacao: []
			},
			nomeAnexoplanilha: "Clique para selecionar a planilha",
			planilha: "",
			arquivoPlanilha: ""
		};
	},
	created() {
		this.listaCargos();
		this.listarTipos();
		this.buscarAssistenciasFranquias();
	},
	methods: {
		buscarAssistenciasFranquias() {
			listarAssistencias(1).then(resp => {
				this.selectAssistencias = resp.data;
			});
			listarAssistencias(2).then(resp => {
				this.selectFranquias = resp.data;
			});
		},
		listarTipos() {
			listarTipoAutorizada().then(resp => {
				this.tipo = resp.data;
			});
		},
		assistenciaSelecionado() {
			if (this.form.segmentarPorAutorizada != null) {
				this.assistenciaSelecionados.push(this.form.segmentarPorAutorizada);
				this.form.segmentarPorAutorizada = this.assistenciaSelecionados;
			}
		},
		listaCargos() {
			listarCargos().then(resp => {
				this.selectCargo = resp.data;
			});
		},
		atualizarPlanilha(form, planilha, $event) {
			if (!$event.target.files[0]) {
				this[planilha] = null;
				this.nomeAnexoplanilha = "Clique para selecionar a planilha";
				return false;
			}
			this.planilha = $event.target.files[0];
			const reader = new FileReader();
			reader.onload = bg => {
				this[planilha] = bg.target.result;
			};
			reader.readAsDataURL($event.target.files[0]);
			this.nomeAnexoplanilha = $event.target.files[0].name;
		},
		removerPlanilha(form, planilha, elem) {
			this[planilha] = null;
			this.nomeAnexoplanilha = "Selecione uma planilha";
			document.querySelector(`#${elem}`).value = null;
		},

		fecharSelect() {
			if (!this.form.cliente || this.form.cliente.length < 1) {
				this.form.cliente = null;
			}
		},

		formatarData(data) {
			return moment(data).format("YYYY-MM-DD");
		},

		salvar() {
			let id = this.$route.params.id;

			const data = new FormData();
			data.append("Id", id);
			data.append("PorCPF", this.form.cpf);
			this.form.segmentarPorAutorizada.map(item => {
				data.append("IdsSegmentacao", item.id);
			});
			data.append("Planilha", this.arquivoPlanilha);
			adicionarNovaSegmentacao(data).then(resp => {
				if (resp.data.sucesso) {
					this.$store.commit("SET_MODAL", {
						ativado: true,
						mensagem: resp.data.mensagem,
						tipo: "sucesso"
					});
					setTimeout(() => {
						this.$store.commit("SET_MODAL", {
							ativado: false,
							mensagem: "",
							tipo: ""
						});
					}, 2000);
					this.$router.push({ name: "cadastroNotificacoes" });
				}
			});
		}
	}
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"/>

<style lang="scss" scoped>
#box-filtro-assistencia {
	width: 100%;

	.tabela-informacoes {
		height: auto;
		max-height: 100px;
	}

	.tabela-linha.tabela-linha-informacoes .coluna-nome-assistencia {
		width: 250px;
		padding: 0px 10px;
		margin-bottom: 4px;
	}

	.quebra-linha {
		flex-wrap: wrap;
	}

	.box-colunas-assistencia {
		width: 100%;
		display: flex;
	}
}

.box-excluir-todos {
	width: 100%;
	margin-left: 15px;
	display: flex;
}

.btn-excluir-assistencia,
.btn-excluir {
	margin: 10px 0;
	font-weight: 800;
	width: auto;
	color: #fff;
	padding: 10px;
	border-radius: 5px;
	background: #f28809;
	text-align: center;
	cursor: pointer;
	display: block;
}

.btn-excluir-assistencia {
	padding: 5px;
	border: none;
	width: 30px;
}

#planilha-flex {
	display: flex;
	justify-content: center;
}

.planilha-btn {
	display: flex;
	-ms-flex-align: center;
	-ms-flex-pack: center;
	justify-content: center;
	margin: 5px 0 10px 0;
	cursor: pointer;
	transition: 0.3s;
	color: #fff;
	border-radius: 8px;
	background: #33b933;
	padding: 6px 20px;
}

#txt-msg {
	width: 100%;

	.textarea-msg {
		width: 100%;
		padding: 8px;
		height: 100px;
		max-height: max-content;
	}
}
</style>
